export const violationFields = [
  {
    title: 'Описание нарушения',
    model: 'description',
    type: 'textarea',
    rows: 6,
    read_only: true
  },
  {
    title: 'Требования нормативного документа',
    model: 'norm_doc_requirements',
    type: 'textarea',
    rows: 6
  }
]
