<template>
  <div class="violation-item">
    <r-block
      col
      space-between
      no-padding
    >
      <r-block
        no-padding
        col
        start
      >
        <r-text>{{ title }}</r-text>
        <r-delete-button
          mini
          @beforeDelete="deleteViolation"
        />
      </r-block>
      <r-button
        simple
        :icon="!active ? 'chevron-down' : 'chevron-up' "
        @click="active = !active"
      >
        {{ active ? 'Свернуть' : 'Развернуть' }}
      </r-button>
    </r-block>
    <r-block
      v-if="active"
      no-padding
    >
      <attributes-list
        :is-editing="editMode"
        :model="violationFields"
        :source="violation"
        @hasChanges="$emit('handle-change')"
      />
      <files-comments
        :id="violation.id"
        :source_id="violationsSource"
        only="files"
        no-toggler
        column
      />
    </r-block>
  </div>
</template>

<script>
import { violationFields } from './configs/'

export default {
  components: {
    attributesList: () => import('@/components/globals/r-modal-elements/attributes-list'),
    filesComments: () => import('@/components/files-comments/files-comments')
  },
  props: {
    title: {
      type: String,
      default: 'Нарушение'
    },
    source: {
      type: Object,
      required: true
    },
    violation: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false,
      violationsSource: 'public.act_violations',
      actsSource: this.$store.state.bdd.actsUrl,
      violationFields
    }
  },
  methods: {
    async deleteViolation() {
      try {
        this.loading = true

        const item = {
          id: this.source.id,
          act_violations: [{
            id: this.violation.id,
            disabled: true
          }]
        }

        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.actsSource}`,
          data: item
        })
        this.$emit('handle-update')
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.violation-item {
  display: grid;
  grid-gap: 0.5rem;
  padding: 1rem;
  border-radius: var(--border-radius);
  background-color: var(--bg_containers);
  border: 1px solid var(--dividers_low_contrast);
}
</style>
